import {
  AppBar,
  Avatar,
  Box,
  Breadcrumbs,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
} from '@mui/material'

import logoMini from '../logo_mini.png'
import { ArrowRight } from '@mui/icons-material'
import { useEffect, useMemo, useState } from 'react'
import {
  getDecodedToken,
  getSelectedClientId,
  getSelectedClientName,
  isResolvexMember,
  setSelectedClientId,
  setSelectedClientName,
} from '@resolvex/resolvex-sdk'
import { useGetAllClientsQuery } from 'graphql/gen-types'
import { getContext } from 'utils/store'
import { useLocation } from 'react-router-dom'
import { NotificationsBell } from './notificationsBell'

interface Clients {
  name: string
  id: number
}

export const Header = (): JSX.Element => {
  const { role, firstName, lastName, clientName, id } = getDecodedToken()
  const location = useLocation()
  const { data, loading, error } = useGetAllClientsQuery()
  const { setAlertMessage, navigate } = getContext()

  const [availableClients, setAvailableClients] = useState<Clients[]>([])

  const paths = useMemo(() => {
    return location.pathname.split('/')
  }, [location])

  const queries = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location])

  const isClientSelectAllowed = isResolvexMember(role)

  useEffect(() => {
    if (error) {
      setAlertMessage(`System Failure: ${error}`)
      return
    }

    if (!loading && data?.getAllClients) {
      const newClients = data?.getAllClients.filter(
        (client: Clients) => client.name !== 'template'
      )
      setAvailableClients(newClients)
    }
  }, [data, error, loading, setAlertMessage])

  const handleClientSelect = (
    event: SelectChangeEvent<string>,
    _: React.ReactNode
  ): void => {
    const clientId = Number(event.target.value)
    const clientName = availableClients.find(
      (client) => client.id === clientId
    )?.name

    if (clientName) setSelectedClientName(clientName)
    if (clientId) setSelectedClientId(clientId)
    window.location.reload()
  }

  return (
    <AppBar position="relative" sx={{ height: 112 }}>
      <Toolbar>
        <img
          style={{ width: '64px', height: 'auto' }}
          src={logoMini}
          alt="resolvexLogo"
        />
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ArrowRight />}
          sx={{ pl: (theme) => theme.spacing(2), flexGrow: 1 }}
        >
          {paths.map((path, index) => {
            let disabled = false

            if (path === 'contacts' && queries.get('session') !== null) {
              path = 'sessions'
            }

            if (path === 'groups') {
              path = 'contacts'
            }

            if (index === 1) {
              disabled = true
            }

            return (
              path.length > 0 && (
                <Typography
                  key={path + index}
                  variant="h5"
                  sx={{
                    cursor: disabled ? 'none' : 'pointer',
                    pointerEvents: disabled ? 'none' : 'all',
                    textTransform: 'capitalize',
                    color: (theme) =>
                      paths.length - 1 === index
                        ? theme.palette.primary.main
                        : theme.palette.info.main,
                  }}
                  onClick={() => {
                    const p = paths.slice(1, index)
                    p.push(path)
                    navigate('/' + p.join('/'))
                  }}
                >
                  {path}
                </Typography>
              )
            )
          })}
        </Breadcrumbs>

        {clientName && id && <NotificationsBell />}

        <Box
          sx={{
            paddingLeft: 2,
            m: 1,
            minWidth: 120,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body1">{firstName + ' ' + lastName}</Typography>

          {!isClientSelectAllowed && (
            <Typography variant="body2">{getSelectedClientName()}</Typography>
          )}

          {isClientSelectAllowed && (
            <FormControl variant="standard" fullWidth>
              <InputLabel id="clientSelect">Client</InputLabel>
              <Select
                name="clientSelect"
                labelId="clientSelectLabelId"
                id="clientSelectId"
                value={getSelectedClientId().toString()}
                onChange={handleClientSelect}
                label="Client"
              >
                {availableClients.length === 0 && (
                  <MenuItem value={getSelectedClientId().toString()}>
                    {getSelectedClientName()}
                  </MenuItem>
                )}
                {availableClients.length > 0 &&
                  availableClients.map((client) => {
                    return (
                      <MenuItem key={client.id} value={client.id.toString()}>
                        {client.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          )}
        </Box>
        <Avatar
          sx={{
            width: 28,
            height: 28,
          }}
        />
      </Toolbar>
    </AppBar>
  )
}
