import { Button, Grid, Typography } from '@mui/material'
import { FormModal } from './formModal'

interface AlertDialogProps {
  alert: string
  setAlert: (alertMessage: string) => void
}

export function AlertDialog(props: AlertDialogProps): JSX.Element {
  const { alert, setAlert } = props
  let isDialogOpen = false
  alert !== '' ? (isDialogOpen = true) : (isDialogOpen = false)
  return (
    <FormModal
      open={isDialogOpen}
      color="error"
      onClose={() => setAlert('')}
      width={836}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            {alert}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            disableRipple
            variant="text"
            fullWidth
            onClick={() => setAlert('')}
            color="error"
            autoFocus
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </FormModal>
  )
}
